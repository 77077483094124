import _Motion2 from "./Motion";
import _StaggeredMotion2 from "./StaggeredMotion";
import _TransitionMotion2 from "./TransitionMotion";
import _spring2 from "./spring";
import _presets2 from "./presets";
import _stripStyle2 from "./stripStyle";
import _reorderKeys2 from "./reorderKeys";
var exports = {};
exports.__esModule = true;

function _interopRequire(obj) {
  return obj && obj.__esModule ? obj["default"] : obj;
}

var _Motion = _Motion2;
exports.Motion = _interopRequire(_Motion);
var _StaggeredMotion = _StaggeredMotion2;
exports.StaggeredMotion = _interopRequire(_StaggeredMotion);
var _TransitionMotion = _TransitionMotion2;
exports.TransitionMotion = _interopRequire(_TransitionMotion);
var _spring = _spring2;
exports.spring = _interopRequire(_spring);
var _presets = _presets2;
exports.presets = _interopRequire(_presets);
var _stripStyle = _stripStyle2;
exports.stripStyle = _interopRequire(_stripStyle); // deprecated, dummy warning function

var _reorderKeys = _reorderKeys2;
exports.reorderKeys = _interopRequire(_reorderKeys);
export default exports;
export const __esModule = exports.__esModule,
      Motion = exports.Motion,
      StaggeredMotion = exports.StaggeredMotion,
      TransitionMotion = exports.TransitionMotion,
      spring = exports.spring,
      presets = exports.presets,
      stripStyle = exports.stripStyle,
      reorderKeys = exports.reorderKeys;